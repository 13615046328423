<template>
  <div class="crumb">
     <i class="el-icon-arrow-left"></i>
     <el-breadcrumb separator-class="el-icon-arrow-left">
      <el-breadcrumb-item
        v-for="(item, index) in items"
        :to="{ path: item.path }"
        :key="index"
        style="font-size: 12px;"
      >
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  name: 'Crumb',
  props: {
    /** 面包屑的数据 */
    items: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.crumb{
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 50px;
  text-align: left;
  background-color: #fff;
  margin-bottom:10px;
  padding-left:20px;
  box-shadow: 0px 3px 3px 0px rgba(211, 211, 211, 0.27);
}
.crumb ::v-deep{
    .el-breadcrumb{
        height: 50px;
        line-height: 50px;
        padding-left:5px;
    }
}
</style>
