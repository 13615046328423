<template>
  <div class="authProgress">
    <AppHeader :type="1"></AppHeader>
    <div style="width:1200px;margin:0 auto;padding-top:80px;">
      <Crumb :items="crumbData"></Crumb>
    </div>
    <div class="content">
      <div class="left">
        <div class="left-top">
          <div class="company-icon">
            <img src="@/assets/head.png" alt="" />
          </div>
          <p class="company-name">{{ form.corName }}</p>
        </div>
        <div class="left-bottom">
          <div class="path-item active">
            <router-link to="/accountInfo" tag="span">账号信息</router-link>
          </div>
          <div class="path-item">
            <router-link to="/bind" tag="span">至信链身份绑定</router-link>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="elem-item">
          <div class="elem-title">
            <p class="title-label">账号信息</p>
          </div>
          <div class="elem-content">
            <el-form :inline="true" label-width="150px">
              <el-form-item label="邮箱:">
                <p class="info-value">{{ form.email }}</p>
              </el-form-item>
              <el-form-item label="密码:">
                <span class="info-value">*********</span>
                <i @click="pwdEditClick" class="el-icon-edit"></i>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 基本信息 -->
        <div>
          <div class="elem-item">
            <div class="elem-title">
              <div class="base-title-label">
                <p class="title-label base-title-label">
                  基本信息
                  <span class="status pengding" v-if="status === 1">
                    <i class="icon icon-shenghe_pending"></i>{{ statusText }}</span
                  >
                  <span class="status auditPass" v-if="status === 2">
                    <i class="icon icon-dui"></i>{{ statusText }}</span
                  >
                  <span class="status auditFaild" v-if="status === 3">
                    <i class="icon icon-shenhe_faild"></i>{{ statusText }}</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="elem-item">
            <div class="base-elem-title">
              <div style="flex-wrap: nowrap;display: flex;">
                <p class="title-label">
                  企业信息
                  <!-- <i
                    @click="enterpriseInfo"
                    v-if="status == 3"
                    class="el-icon-edit"
                  ></i> -->
                </p>
                <!-- <p class="auth-tip" v-if="status == 3">
                  您的申请信息审核未通过，请按驳回消息<span
                    style="color: #2f5ab2"
                    @click="enterpriseInfo"
                    >更新信息</span
                  >
                </p> -->
              </div>
              <!-- <p @click="accessDoc" class="title-right">接入指引 >></p> -->
            </div>
            <div class="elem-content">
              <el-form :inline="true" label-width="155px">
                <el-form-item label="企业名称:">
                  <p class="info-value">{{ form.corName }}</p>
                </el-form-item>
                <el-form-item label="社会统一信用代码:">
                  <span class="info-value">{{ form.credit }}</span>
                </el-form-item>
                <el-form-item label="法定代表人:">
                  <span class="info-value">{{ form.represent }}</span>
                </el-form-item>
                <el-form-item label="所属行业:">
                  <span
                    :title="category"
                    class="info-value"
                    style="width:300px;white-space: nowrap;
    text-overflow: ellipsis;
    text-align:left;
    overflow: hidden;
    word-break: break-all;"
                    >{{ category }}</span
                  >
                </el-form-item>
                <el-form-item label="营业执照:">
                  <img
                    v-if="imageUrl"
                    @click="
                      viewImg(
                        pdfUrl ? pdfUrl : imageUrl,
                        pdfUrl ? 'pdf' : 'image'
                      )
                    "
                    :src="imageUrl"
                    alt=""
                  />
                </el-form-item>
                <el-form-item label="委托公函:">
                  <img
                    v-if="imageUrl1"
                    @click="
                      viewImg(
                        pdfUrl1 ? pdfUrl1 : imageUrl1,
                        pdfUrl1 ? 'pdf' : 'image'
                      )
                    "
                    :src="imageUrl1"
                    alt=""
                  />
                </el-form-item>
              </el-form>
              <!-- <div class="audit-status">
                <img v-if="status === 1" src="@/assets/pending.png" alt="" />
                <img v-if="status === 2" src="@/assets/auditPass.png" alt="" />
                <img v-if="status === 3" src="@/assets/auditFaild.png" alt="" />
              </div> -->
            </div>
          </div>
          <div class="elem-item">
            <div class="base-elem-title">
              <div style="flex-wrap: nowrap;display: flex;">
                <p class="title-label">
                  申请人信息
                  <!-- <i
                    @click="applicantAuth"
                    v-if="exist === 0 && status == 3"
                    class="el-icon-edit"
                  ></i> -->
                </p>
                <!-- <p class="auth-tip" v-if="exist === 0 && status == 3">
                  您的申请信息审核未通过，请按驳回消息<span
                    style="color: #2f5ab2"
                    @click="applicantAuth"
                    >更新信息</span
                  >
                </p> -->
              </div>
            </div>
            <div class="elem-content elem-content-last">
              <el-form :inline="true" label-width="150px">
                <el-form-item label="姓名:">
                  <p class="info-value">{{ form.applyName }}</p>
                </el-form-item>
                <el-form-item label="联系电话:">
                  <span class="info-value">{{ form.applyPhone }}</span>
                </el-form-item>
                <el-form-item label="申请人职务:">
                  <span class="info-value">{{ form.title }}</span>
                </el-form-item>
                <el-form-item label="身份证号:">
                  <span class="info-value">{{ form.idcard }}</span>
                </el-form-item>
                <el-form-item label="身份证正面:">
                  <img
                    v-if="imageUrl2"
                    @click="
                      viewImg(
                        pdfUrl2 ? pdfUrl2 : imageUrl2,
                        pdfUrl2 ? 'pdf' : 'image'
                      )
                    "
                    :src="imageUrl2"
                    alt=""
                  />
                  <div v-else>
                    <img style="width: 84px" src="@/assets/upload.png" alt="" />
                    <img src="@/assets/idCardFront.png" alt="" />
                  </div>
                </el-form-item>
                <el-form-item label="身份证反面:">
                  <img
                    v-if="imageUrl3"
                    @click="
                      viewImg(
                        pdfUrl3 ? pdfUrl3 : imageUrl3,
                        pdfUrl3 ? 'pdf' : 'image'
                      )
                    "
                    :src="imageUrl3"
                    alt=""
                  />
                  <div v-else>
                    <img style="width: 82px" src="@/assets/upload.png" alt="" />
                    <img src="@/assets/idCardBack.png" alt="" />
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter></AppFooter>
    <el-dialog width="600px" class="img-dialog" :visible.sync="imgDialog">
      <iframe
        height="500px"
        v-if="viewType === 'pdf'"
        frameborder="0"
        width="100%"
        :src="previewImg"
        alt=""
      ></iframe>
      <img v-else :src="previewImg" style="width: 100%" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import AppHeader from "@/components/layer/AppHeader";
import AppFooter from "@/components/layer/AppFooter";
import {
  getApply,
  downloadbusiLicense,
  downloadletter,
  downloadCardFront,
  downloadCardBack,
  getTradeParent,
  getTradeChild,
  checkIdCard
} from "@/api/user";

const defaultImg = require("@/assets/pdf.png");
import Crumb from "@/components/common/Crumb";

export default {
  components: {
    AppHeader,
    AppFooter,
    Crumb
  },
  data() {
    return {
      imgDialog: false,
      status: 1,
      viewType: "",
      previewImg: "",
      category: "",
      form: {},
      imageUrl: "",
      imageUrl1: "",
      imageUrl2: "",
      imageUrl3: "",
      pdfUrl: "",
      pdfUrl1: "",
      pdfUrl2: "",
      pdfUrl3: "",
      exist: 0,
      statusText: "",
      crumbData: [
        {
          name: "账号中心",
          path: ""
        },
        {
          name: "账号信息",
          path: ""
        }
      ]
    };
  },
  mounted() {
    const that = this;
    this.idCardCheck().then(res=>{
       if(this.exist == 1){
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        // that.status = 3
        getApply().then(res => {
          this.loading.close();
          if (res.retCode === 0) {
            that.form.email = res.detail.email;
            that.form.corName = res.detail.epName;
            that.form.credit = res.detail.creditCode;
            that.form.represent = res.detail.representative;
            that.form.applyName = res.detail.contact;
            that.form.applyPhone = res.detail.mobile;
            that.form.title = res.detail.title;
            that.form.idcard = res.detail.idcard;
            that.busiLicenseId = res.detail.busilicenseid;
            that.letterId = res.detail.officialLetterId;
            that.status = res.detail.state;
            that.categoryCid = res.detail.categoryCid;
            that.downloadbusiLicense(res.detail.busilicenseid);
            that.downloadletter(res.detail.officialLetterId);
            that.downloadCardFront(res.detail.idcardfrontid);
            that.downloadCardBack(res.detail.idcardbackid);
            that.getTradeParent(res.detail.categoryPid);
            if (that.status === 1) {
              that.statusText = "信息审核中";
            } else if (that.status === 2) {
              that.statusText = "信息审核通过";
            } else {
              that.statusText = "信息审核不通过";
            }
          } else {
            this.$message.error(res.retMsg);
          }
        });
       }
     })
  },
  methods: {
    pwdEditClick() {
      this.$router.push({
        name: "modifyPass"
      });
    },
    accessDoc() {
      this.$router.push({
        name: "documentcenter"
      });
    },
    applicantAuth() {
      this.$router.push({
        name: "applicantAuth"
      });
    },
    enterpriseInfo() {
      this.$router.push({
        name: "enterpriseInfo"
      });
    },
    getTradeParent(id) {
      const data = {
        pid: id
      };
      const that = this;
      getTradeParent(data).then(res => {
        this.$message.closeAll();
        if (res.retCode === 0) {
          this.category = res.detail[0].name;
          this.getTradeChilds(res.detail[0].id);
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    getTradeChilds(pid) {
      const data = {
        pid
      };
      getTradeChild(data).then(res => {
        this.$message.closeAll();
        if (res.retCode === 0) {
          res.detail.forEach(item => {
            if (item.id === this.categoryCid) {
              this.category = `${this.category}/${item.name}`;
            }
          });
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    idCardCheck() {
      let that = this;
       return new Promise(function(reslove,reject){
              checkIdCard().then(res => {
                that.$message.closeAll();
                if (res.retCode == 0) {
                  that.exist = res.exist;
                  if(!that.exist){ // 尚未实名
                      that.$confirm('尚未实名认证,是否去实名?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        that.$router.push({ path: '/enterpriseInfo' })
                      }).catch(() => {
                      });
                  }
                  reslove(res.exist)
                } else {
                  that.$message.error(res.retMsg);
                  reject()
                }
              });
       })
    },
    viewImg(url, type) {
      this.previewImg = url;
      this.viewType = type;
      if (type == "pdf") {
        const isIE = window.ActiveXObject || "ActiveXObject" in window;
        if (isIE) {
          this.$confirm(
            '<i style="font-size: 54px;color: #D34349;" class="el-icon-warning"></i><span style="font-size:16px;position: relative;top: -10px;margin-left: 10px;">该浏览器暂不支持pdf预览，推荐使用chrome浏览器</span>',
            "提示",
            {
              confirmButtonText: "确定",
              dangerouslyUseHTMLString: true,
              center: true,
              showCancelButton: false,
              confirmButtonClass: "comfirm"
            }
          ).then(() => {});
        } else {
          this.imgDialog = true;
        }
      } else {
        this.imgDialog = true;
      }
    },
    downloadbusiLicense(busiLicenseId) {
      const that = this;
      downloadbusiLicense(busiLicenseId)
        .then(
          res =>
            `data:${res.headers["content-type"]};base64,${btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`
        )
        .then(res => {
          if (res.indexOf("application/pdf") < 0) {
            that.imageUrl = res;
          } else {
            that.pdfUrl = res;
            that.imageUrl = defaultImg;
          }
        });
    },
    downloadletter(officialLetterId) {
      const that = this;
      downloadletter(officialLetterId)
        .then(
          res =>
            `data:${res.headers["content-type"]};base64,${btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`
        )
        .then(res => {
          if (res.indexOf("application/pdf") < 0) {
            that.imageUrl1 = res;
          } else {
            that.pdfUrl1 = res;
            that.imageUrl1 = defaultImg;
          }
        });
    },
    downloadCardFront(cardFrontId) {
      const that = this;
      downloadCardFront(cardFrontId)
        .then(
          res =>
            `data:${res.headers["content-type"]};base64,${btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`
        )
        .then(res => {
          this.$message.closeAll();
          if (res.indexOf("application/pdf") < 0) {
            that.imageUrl2 = res;
          } else {
            that.pdfUrl2 = res;
            that.imageUrl2 = defaultImg;
          }
        });
    },
    downloadCardBack(cardBackId) {
      const that = this;
      downloadCardBack(cardBackId)
        .then(
          res =>
            `data:${res.headers["content-type"]};base64,${btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`
        )
        .then(res => {
          this.$message.closeAll();
          if (res.indexOf("application/pdf") < 0) {
            that.imageUrl3 = res;
          } else {
            that.pdfUrl3 = res;
            that.imageUrl3 = defaultImg;
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.authProgress {
  min-height: 100%;
  background: #eff1f4;
  a {
    color: #4066d0;
  }
  .content {
    display: flex;
    width: 1200px;
    height: auto;
    margin: 0px auto;
    box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
    .left {
      width: 200px;
      flex-shrink: 0;
      background: #f7f8fa;
      justify-content: center;
      flex-wrap: wrap;
      .left-top {
        height: 170px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        border-bottom: 1px solid #e6e6e6;
        .company-icon {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 30px;
          img {
            width: 100%;
          }
        }
        .company-name {
          color: #333333;
          font-size: 18px;
          width: 100%;
          text-align: center;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      .left-bottom {
        width: 100%;
        .path-item {
          width: 100%;
          height: 54px;
          position: relative;
          margin-top: 0px;
          text-align: center;
          font-size: 16px;
          color: #2f5ab2;
          line-height: 54px;
          cursor: pointer;
        }
        .active {
          background-color: rgba($color: #2f5ab2, $alpha: 0.2);
        }
        .path-item.active::after {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 8px;
          height: 100%;
          background-color: #2f5ab2;
        }
      }
    }
    .right {
      width: 100%;
      padding: 35px;
      background: #fff;
      .elem-item {
        width: 100%;
        margin-bottom: 40px;
        .base-elem-title {
          position: relative;
          display: flex;
          justify-content: space-between;
          height: 40px;
          margin-left: 20px;
          color: #333333;
        }
        .elem-title {
          position: relative;
          display: flex;
          justify-content: space-between;
          height: 40px;
          .base-title-label {
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: #fff;

            .status {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 30px;
              line-height: 30px;
              font-size: 14px;
              color: #fff;
              background: #5ea032;
              padding: 0 13px;
              border-radius: 15px;
              i {
                color: #fff;
                padding-right:6px;
              }
            }
            .pengding {
              background: rgb(219,162,70);
            }
            .auditPass {
              background: rgb(111,157,70);
            }
            .auditFaild {
             background: rgb(202,68,44);
            }
          }
          .title-label {
            font-size: 16px;
            color: #333333;
            margin-left: 12px;
            margin-top: 0;

            i {
              cursor: pointer;
              color: #2f5ab2;
            }
          }
          .auth-tip {
            margin: 0;
            margin-left: 5px;
            color: #333333;
            span {
              cursor: pointer;
            }
          }
          .title-right {
            font-size: 16px;
            color: #2f5ab2;
            margin: 0;
            cursor: pointer;
          }
          border-bottom: 1px solid #e6e6e6;
        }
        .elem-title::after {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          height: 24px;
          width: 6px;
          border-radius: 3px;
          background-color: #2f5ab2;
        }
        .elem-content {
          position: relative;
          border-bottom: 1px solid #ccc;
          ::v-deep .el-form {
            margin-top: 20px;
            width: 100%;
            .el-form-item__label {
              color: #999999;
              font-size: 16px;
            }
            .el-form-item {
              width: 50%;
              margin-right: 0;
              .el-form-item__content {
                width: calc(100% - 155px);
              }
              img {
                width: 128px;
              }
              .info-value {
                margin: 0;
                color: #606266;
                font-size: 16px;
                padding-right: 1em;
                text-align: center;
                display: inline-block;
                word-break: break-all;
              }
              .el-icon-edit {
                color: #2f5ab2;
                font-size: 18px;
                margin-left: 16px;
                cursor: pointer;
              }
            }
          }
          .audit-status {
            position: absolute;
            right: -5px;
            top: -22px;
            width: 100px;
            height: 100px;
            img {
              width: 100%;
            }
          }
        }
        .elem-content-last {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
